

































































import {
  computed,
  defineComponent,
  onBeforeUnmount,
  onMounted,
  ref,
  useContext,
  useMeta,
  useRoute,
  useRouter,
  watch
} from '@nuxtjs/composition-api';
import { onSSR } from '@vue-storefront/core';
import { useMediaQuery } from '@vueuse/core';
import throttle from 'lodash.throttle';
import VaimoTopHeaderMobile from 'organisms/header/mobile/VaimoTopHeaderMobile.vue';
import VaimoTopHeader from 'organisms/header/VaimoTopHeader.vue';
import type { Route } from 'vue-router';

import LoadWhenVisible from '~/components/utils/LoadWhenVisible.vue';
import {
  useCart,
  useConfig,
  useStore,
  useUiState,
  useUser
} from '~/composables';
import AppFooter from '~/diptyqueTheme/components/AppFooter.vue';
import TailwindSafeClasses from '~/diptyqueTheme/components/atoms/SafeClasses.vue';
import VaimoLoader from '~/diptyqueTheme/components/atoms/VaimoLoader.vue';
import Account from '~/diptyqueTheme/components/organisms/account/index.vue';
import VaimoMiniCart from '~/diptyqueTheme/components/organisms/miniCart/VaimoMiniCart.vue';
import VaimoPromoBanner from '~/diptyqueTheme/components/organisms/VaimoPromoBanner.vue';
import {
  useGoogleTagManager,
  useIPData,
  usePreviewMode,
  useThemeUiState
} from '~/diptyqueTheme/composable';
import { useBooxi } from '~/diptyqueTheme/composable/useBooxi';
import { useGetRedirect } from '~/diptyqueTheme/composable/useGeoIpRedirect';
import {
  lsGet,
  lsRemove,
  lsSet
} from '~/diptyqueTheme/composable/useLocalStorage';
import abTastyScripts from '~/diptyqueTheme/config/abTastyScripts';
import { useAccountStore } from '~/diptyqueTheme/stores/account';
import { useErrorLayoutStatusStore } from '~/diptyqueTheme/stores/error';
import { useMegaMenuMobileStore } from '~/diptyqueTheme/stores/megaMenuMobile';
import cartGetters from '~/modules/core/getters/cartGetters';
import { useCartStore } from '~/modules/core/stores/cart';

import { useNostoApi } from '../composable/nosto/useNostoApi';

export default defineComponent({
  name: 'DefaultLayout',

  components: {
    VaimoLoader,
    LoadWhenVisible,
    TailwindSafeClasses,
    VaimoPromoBanner,
    AppFooter,
    VaimoTopHeaderMobile,
    VaimoTopHeader,
    VaimoMiniCart,
    NostoSession: () => import('atoms/NostoSession.vue'),
    NostoScript: () => import('atoms/NostoScript.vue'),
    VaimoRedirectModal: () =>
      import('~/diptyqueTheme/components/organisms/VaimoRedirectModal.vue'),
    LoginModal: () =>
      import('~/modules/customer/components/LoginModal/LoginModal.vue'),
    VaimoStoreSwitcherModal: () =>
      import(
        '~/diptyqueTheme/components/organisms/VaimoStoreSwitcher/VaimoStoreSwitcherModal.vue'
      ),
    VaimoMobileMenu: () =>
      import('organisms/header/mobile/VaimoMobileMenu.vue'),
    Account,
    VaimoContentLayer: () => import('molecules/VaimoContentLayer.vue'),
    ContentfulPreviewPanel: () =>
      import('organisms/ContentfulPreviewPanel.vue'),
    VaimoScrollToTop: () =>
      import('~/diptyqueTheme/components/atoms/VaimoScrollToTop.vue')
  },
  setup() {
    const { app } = useContext();
    const route = useRoute();
    const { isPreviewMode } = usePreviewMode();
    const { getRedirectData, redirectLoader, redirectToStore } = useGetRedirect(
      app.context
    );
    const router = useRouter();
    const routePath = computed(() => route.value.path);
    const { load: loadStoresConfig, config: configStore } = useConfig();
    const { load: loadCart, cart: cart } = useCart();
    const { load: loadUser, user, isAuthenticated, logout } = useUser();
    const { getPageviewDetails, getOpenLoginDetails, EVENT_TIMEOUT } =
      useGoogleTagManager();
    const accountStore = useAccountStore();
    const errorLayoutStatusStore = useErrorLayoutStatusStore();
    const { setCartInLocalStorageByCode } = useStore();
    const getProductsTotalQuantity = computed(() => {
      const samplesLength =
        cartGetters
          .getItems(cart.value)
          .filter((item) => cartGetters.getItemPrice(item).regular === 0)
          ?.length || 0;
      return cartGetters.getTotalItems(cart.value) - samplesLength;
    });
    const isLoading = computed(() => redirectLoader.value);
    const langClass = 'lang-' + app.i18n.localeProperties.code;
    const { booxiConfig } = useBooxi();
    const { booking: booxiData } = route.value.query;
    const cartStore = useCartStore();
    const isCartLoading = computed(() => cartStore.is_cart_loading);
    const addingItemsCount = computed(() => cartStore.adding_items_count);

    const loadCartFinished = ref(false);
    const { getCountryCodeByIP, userCountry } = useIPData();
    const checkCartStatus = () => {
      if (loadCartFinished.value) {
        loadCart({ realCart: true, forceReload: true });
        loadCartFinished.value = false;
      }
    };

    watch(() => getProductsTotalQuantity.value, checkCartStatus);

    watch(
      () => isCartLoading.value,
      (newValue) => {
        if (!newValue) {
          loadCartFinished.value = true;
        }
      }
    );

    watch(
      () => addingItemsCount.value,
      (newValue) => {
        if (newValue === 0) {
          loadCartFinished.value = true;
          checkCartStatus();
        } else {
          loadCartFinished.value = false;
        }
      }
    );

    const {
      isCartSidebarOpen,
      isLoginModalOpen,
      toggleLoginModal,
      toggleCartSidebar,
      toggleSearchState
    } = useUiState();
    const { isStoreSwitcherModalOpen, closeStoreSwitcherModal } =
      useThemeUiState();
    const megaMenuMobileStore = useMegaMenuMobileStore();
    const isMobileMenuVisible = computed(() => megaMenuMobileStore.open);
    const isDesktop = useMediaQuery('(min-width: 1024px)');

    const showHideMobileMenu = () => {
      megaMenuMobileStore.open
        ? megaMenuMobileStore.hide()
        : megaMenuMobileStore.show();
    };

    const vaimoHeader = ref(null);
    const vaimoHeaderMobileInner = ref(null);
    const vaimoHeaderHeight = ref(null);
    const vaimoHeaderMobileInnerHeight = ref(null);
    const headerTopNav = ref(null);
    const headerTopNaveHeight = ref(null);
    const topHeaderWrapper = ref(null);

    // TODO: Move the scrolling logic into a separate composable
    let lastScroll = 0;
    let scrolling = ref(null);
    const scrollingClass = computed(() =>
      scrolling.value ? 'scroll-' + scrolling.value : false
    );

    const scrollTimer = ref(-1);
    // eslint-disable-next-line no-magic-numbers
    const modifier = ref(80);

    const setModifierValue = () => {
      const storedPromoValue = lsGet('show-promo');

      if (
        storedPromoValue &&
        storedPromoValue.expirationDate > new Date().getTime()
      ) {
        if (isDesktop.value) {
          modifier.value = vaimoHeaderHeight.value;
        } else {
          modifier.value = vaimoHeaderMobileInnerHeight.value;
        }
      } else {
        if (isDesktop.value) {
          // eslint-disable-next-line no-magic-numbers
          modifier.value = vaimoHeaderHeight.value + 34;
        } else {
          // eslint-disable-next-line no-magic-numbers
          modifier.value = vaimoHeaderMobileInnerHeight.value + 52;
        }
      }
    };

    const SCROLL_THROTTLE = 30;
    const scrollHandler = throttle(
      () => {
        const currentScroll =
          document.documentElement.scrollTop || document.body.scrollTop;

        setModifierValue();

        if (!topHeaderWrapper.value) return;
        if (currentScroll <= modifier.value) {
          scrolling.value = null;
          topHeaderWrapper.value.style.paddingBottom = '0';
        } else if (
          currentScroll > lastScroll &&
          scrolling.value !== 'down' &&
          !isCheckout.value
        ) {
          scrolling.value = 'down';
          topHeaderWrapper.value.style.paddingBottom = `${headerTopNaveHeight.value}px`;
        } else if (currentScroll < lastScroll && scrolling.value !== 'up') {
          scrolling.value = 'up';
        }
        lastScroll = currentScroll;

        // Setting proper scrolling value on scroll stop

        if (scrollTimer.value !== -1) clearTimeout(scrollTimer.value);
        scrollTimer.value = window.setTimeout(() => {
          scrolling.value = currentScroll ? 'up' : null;
          // eslint-disable-next-line no-magic-numbers
        }, 300);
      },
      SCROLL_THROTTLE,
      { leading: true }
    );

    const triggerPageview = (to: Route) => {
      const statusCode =
        errorLayoutStatusStore.getErrorStatusCode()?.toString() ?? null;
      const pageviewDetails = getPageviewDetails(
        user.value,
        isAuthenticated.value,
        to,
        statusCode
      );

      if (pageviewDetails) {
        setTimeout(() => {
          app.$gtm.push(pageviewDetails);
        }, EVENT_TIMEOUT);
      }
    };

    onSSR(async () => {
      const storeConfig = configStore.value;

      if (!storeConfig || Object.keys(storeConfig).length === 0) {
        await loadStoresConfig();
      }
    });

    const apiState = app.context.$vsf.$magento.config.state;
    const changeStoreAndSetCart = async () => {
      //avoid loading cart once again in case of redirect from booxi and from klarna
      if (booxiData || isShopperRedirect.value) return;
      // Remove countries && allowed countries from LS when store changed
      lsRemove('allowed_countries');
      lsRemove('all_countries');

      if (!isCartLoading.value) {
        await loadCart();
      }

      const currentCartId = apiState.getCartId();

      setCartInLocalStorageByCode(currentCartId);

      const storeCart = lsGet('store_cart');
      const storeCartObj = storeCart ? storeCart : { user: {}, guest: {} };
      const userType = isAuthenticated.value ? 'user' : 'guest';
      const userTypeStoreCart = storeCartObj ? storeCartObj[userType] : {};
      const cartId = userTypeStoreCart
        ? userTypeStoreCart[configStore.value.store_code]
        : currentCartId;

      if (cartId) {
        app.$vsf.$magento.config.state.setCartId(cartId);
      } else {
        app.$vsf.$magento.config.state.removeCartId();
      }
    };

    const handleSearchStart = () => {
      toggleSearchState();
      showHideMobileMenu();
    };

    const sendCartGTMEvent = () => {
      app.$gtm.push({
        event: 'click_mini_cart_popin'
      });
    };

    const TOKEN_EXPIRED_LOCAL_KEY = 'vsf-user-token-expired';
    const expiredTokenHandler = async () => {
      if (!isAuthenticated.value) return;

      await logout({});
    };
    const expiredTokenLsHandler = async ({ key, newValue, oldValue }) => {
      if (key !== TOKEN_EXPIRED_LOCAL_KEY) return;

      if (!JSON.parse(oldValue)._value && JSON.parse(newValue)._value) {
        await expiredTokenHandler();
      }
    };

    onMounted(async () => {
      app.$bus.$on('token-expired', expiredTokenHandler);
      window.addEventListener('storage', expiredTokenLsHandler);

      await getCountryCodeByIP();
      if (configStore.value?.vaimo_geoip_general_enable) {
        const redirectData = await getRedirectData();

        if (redirectData.shouldBeRedirected) {
          redirectToStore(redirectData.path);
        }
      }
      await Promise.all([
        loadUser({
          customQuery: {
            customer: 'customerWithOptions'
          }
        }),
        changeStoreAndSetCart()
      ]);

      window.addEventListener('scroll', scrollHandler);

      vaimoHeader.value = document.querySelector('.vaimo-header');
      vaimoHeaderMobileInner.value = document.querySelector(
        '.vaimo-header-mobile__content'
      );
      headerTopNav.value = document.querySelector('.vaimo-header-top-nav');
      topHeaderWrapper.value = document.querySelector('.top-header__wrapper');

      vaimoHeaderHeight.value =
        vaimoHeader.value && vaimoHeader.value.clientHeight;
      vaimoHeaderMobileInnerHeight.value =
        vaimoHeaderMobileInner.value &&
        vaimoHeaderMobileInner.value.clientHeight;
      headerTopNaveHeight.value =
        headerTopNav.value && headerTopNav.value.clientHeight;

      setModifierValue();
      triggerPageview(route.value);

      localStorage.setItem(
        'magento_config',
        JSON.stringify(configStore.value || {})
      );
    });

    router.afterEach((to) => {
      triggerPageview(to);
    });

    const isCheckout = computed(() => {
      const checkout = routePath.value.includes('checkout');
      const success = routePath.value.includes('checkout/thank-you');

      return checkout && !success;
    });

    const isShopperRedirect = computed(() =>
      routePath.value.includes('adyen/handleShopperRedirect')
    );

    const isExternalCart = computed(() =>
      routePath.value.includes('external-cart')
    );

    const checkoutClass = computed(() =>
      isCheckout.value ? 'is-checkout' : ''
    );

    useNostoApi(async (api) => {
      const locale = app.i18n.localeProperties.code;
      const NOSTO_CUSTOMER_KEY = ['nostoSession', locale].join('_');
      api.setAutoLoad(false);
      api.visit.setCustomerIdentifierService({
        getCustomerId: () => {
          return (
            apiState.getNostoSession() || lsGet(NOSTO_CUSTOMER_KEY) || null
          );
        },

        setCustomerId: (id) => {
          apiState.setNostoSession(id);
          lsSet(NOSTO_CUSTOMER_KEY, id);
        }
      });
    }, 'CONFIG');

    onBeforeUnmount(() => {
      app.$bus.$off('token-expired', expiredTokenHandler);
      window.removeEventListener('storage', expiredTokenLsHandler);
      window.removeEventListener('scroll', scrollHandler);
    });

    const metaTags = computed(() => {
      return (
        isPreviewMode.value && {
          meta: [
            {
              hid: 'robots',
              name: 'robots',
              content: 'noindex nofollow'
            }
          ]
        }
      );
    });

    useMeta(() => metaTags.value);

    const toggleAccountSidebar = () => {
      if (isAuthenticated.value) {
        accountStore.isVisible = true;
      } else {
        toggleCartSidebar('account');
      }
    };

    const getClickAccountDetails = () => {
      app.$gtm.push(
        getOpenLoginDetails(route.value.fullPath, isAuthenticated.value)
      );
    };

    const handleAccountToggle = () => {
      toggleAccountSidebar();
      getClickAccountDetails();
    };

    return {
      isLoading,
      isCartSidebarOpen,
      isLoginModalOpen,
      toggleLoginModal,
      showHideMobileMenu,
      handleAccountToggle,
      handleSearchStart,
      getProductsTotalQuantity,
      sendCartGTMEvent,
      isMobileMenuVisible,
      route,
      scrollingClass,
      isStoreSwitcherModalOpen,
      closeStoreSwitcherModal,
      isDesktop,
      toggleCartSidebar,
      isCheckout,
      isExternalCart,
      accountStore,
      errorLayoutStatusStore,
      checkoutClass,
      configStore,
      booxiConfig,
      langClass,
      userCountry,
      cart,
      user,
      isPreviewMode
    };
  },
  head() {
    const abTastyScriptSrc = abTastyScripts[this.$i18n.localeProperties.code];
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const klarnaClientId = this?.configStore?.klarna_pay_instalments_client_id;
    const scriptArray = [];

    if (abTastyScriptSrc && !process.client) {
      scriptArray.push({
        src: abTastyScriptSrc,
        hid: 'abtasty'
      });
    }

    if (this?.booxiConfig?.is_booxi_enabled) {
      scriptArray.push({
        src: this?.booxiConfig?.booxi_script_url,
        hid: 'booxi',
        defer: true
      });
    }

    if (klarnaClientId) {
      scriptArray.push({
        src: 'https://osm.klarnaservices.com/lib.js',
        async: true,
        'data-client-id': klarnaClientId
      });
    }

    return scriptArray.length > 0 ? { script: scriptArray } : {};
  }
});
